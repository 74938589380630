import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import smoothscroll from "smoothscroll-polyfill"
import { Animate } from "react-simple-animate"
import ScrollAnimation from "react-animate-on-scroll"
import { Helmet } from "react-helmet"
import useWindowScrollPosition from "@rehooks/window-scroll-position"

import Layout from "../components/main/layout/Layout"
import { IntroBlocks } from "../components/main/introBlocks/IntroBlocks"
import { Interstill } from "../components/main/Interstil/Interstill"

import s from "./Index.module.scss"
import How from "../images/how.png"
import How1 from "../images/how1.png"
import How2 from "../images/how2.png"
import How3 from "../images/how3.png"
import TileRow from "../images/title-row.png"
import TileRow2 from "../images/title-row2.png"
import Can from "../images/can.jpg"
import Glass1 from "../images/inter-logo.png"

import { Cta } from "../components/common/cta/Cta"
import { Card } from "../components/common/card/Card"
import { Footer } from "../components/main/footer/Footer"
import { Billboard } from "../components/main/billboard/Billboard"

const IndexPage = ({ data }) => {
  const packs = data.allContentfulPack.nodes
  const [showIntro, setShowIntro] = useState(true)
  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  let options = {
    throttle: -1,
  }

  let position
  if (typeof window !== `undefined`) {
    position = useWindowScrollPosition(options)
  }

  const getShiftValue = () => {
    if (typeof window !== `undefined`) {
      return (position.y / window.innerHeight) * 10
    }
  }

  return (
    <Layout showHeaderCta={true} hide={showIntro}>
      <Helmet>
        <script src="https://unpkg.com/share-api-polyfill/dist/share-min.js" />
        <title>Virtual Pub Quiz to do at Home | Tennent’s Pintless</title>
        <meta
          name="description"
          content="Create an own online pub quiz with Pintless, a new pub quiz generator brought to you by Tennent’s. Pick your questions and start hosting your virtual pub quiz."
        />
      </Helmet>
      {showIntro && (
        <Interstill
          close={() => {
            setShowIntro(false)
          }}
        >
          <img src={Glass1} />
        </Interstill>
      )}
      <div className={`${s.Container} ${!showIntro && s.Container___in}`}>
        <div className={s.Intro}>
          <div className={s.Intro__info}>
            <div className={s.Intro__info__inner}>
              <Animate
                play={!showIntro}
                start={{ opacity: 0, transform: `translateY(10%)` }}
                end={{ opacity: 1, transform: `translateY(0%)` }}
                delay={0.2}
                easeType="cubic-bezier(0.445, 0.05, 0.55, 0.95)"
              >
                <h1>
                  Pub quizzing <i>made easy.</i>
                </h1>
                <p>
                  We get it, even the most creative of minds are beginning to
                  flounder at the thought of coming up with another quarantine
                  quiz... so let us help. Simply pick your questions from our
                  many packs, share with pals and let the fun begin from the
                  comfort of your home.
                </p>
                <div className={s.Intro__info__buttons}>
                  <Cta title="Get Started" link="/start" />
                  <Cta
                    title="How does this work?"
                    onClick={() => {
                      if (typeof window === "object") {
                        window.scrollTo({
                          top: window.innerHeight,
                          behavior: "smooth",
                        })
                      }
                    }}
                  />
                </div>
              </Animate>
            </div>
          </div>
          <div className={s.Intro__packs}>
            {/* <img src={How} /> */}
            <IntroBlocks />
          </div>
        </div>

        <div className={s.Quizmasters} id={"wit"}>
          <div className={s.TileRows}>
            <img
              src={TileRow}
              className={s.TileRows__row1}
              style={{
                transform: `translateX(calc(${getShiftValue()}% - 25%)) translateY(-40%) rotate(-15deg)`,
              }}
            />

            <img
              src={TileRow2}
              className={s.TileRows__row2}
              style={{
                transform: `translateX(calc(-${getShiftValue()}% + 5%)) translateY(20%) rotate(-15deg)`,
              }}
            />
          </div>
          <ScrollAnimation
            animateIn="slideInUp"
            offset={300}
            animateOnce={true}
          >
            <div className={s.Quizmasters__inner}>
              <h2>Quizmasters, this one is for you.</h2>
              <p>
                Virtual calls have become a lifeline when you can’t get down to
                your local. Become the host with the most in just a few simple
                steps...
              </p>

              <div className={s.How}>
                <div className={s.How__Item}>
                  <div>
                    <img src={How1} alt="How this works 1" />
                  </div>
                  <h4>Create Your Quiz</h4>
                  <p>
                    Sign up with your email address and setup your quiz details.
                  </p>
                </div>
                <div className={s.How__Item}>
                  <div>
                    <img src={How2} alt="How this works 2" />
                  </div>
                  <h4>Pick Your Packs</h4>
                  <p>
                    We have questions on everything from sport to Tennent’s
                    specials. Pick five packs and build your quiz.
                  </p>
                </div>
                <div className={s.How__Item}>
                  <div>
                    <img src={How3} alt="How this works 3" />
                  </div>
                  <h4>Then Host From Home</h4>
                  <p>
                    Get your pals on a video call, load up the quiz in your
                    browser and share your screen. You are THE quiz master!
                  </p>
                </div>
              </div>
              <Cta
                className={s.Quizmasters__cta}
                title="Get Started"
                link="/start"
              />

              <Cta
                onClick={() => {
                  if (typeof window === "object") {
                    navigator.share(
                      {
                        title: "Pub quizzing, Made Easy.",
                        text: "Pub quizzing, Made Easy. By Tennent's.",
                        url: location.href,
                      },
                      {
                        copy: true,
                        email: false,
                        print: false,
                        sms: true,
                        messenger: true,
                        facebook: true,
                        whatsapp: true,
                        twitter: true,
                        linkedin: true,
                        telegram: true,
                        skype: false,
                        language: "en",
                      }
                    )
                  }
                }}
                className={s.Quizmasters__cta}
                title="Share"
              />
            </div>
          </ScrollAnimation>
        </div>
        <Billboard
          data={{
            title: "What’s a pub quiz without a pint?",
            blurb: "We’re now delivering straight to your door.",
            sub_title: "Tennent’s Delivered",
            image: {
              source_url: Can,
            },
            links: [
              {
                link: {
                  target: "_blank",
                  external: true,
                  title: "Buy Now",
                  url: "https://tennents.co.uk/shop",
                },
              },
            ],
          }}
        />
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allContentfulPack {
      nodes {
        title
        blurb
        color
        difficulty
        cover {
          fixed {
            src
          }
        }
      }
    }
  }
`

export default IndexPage
