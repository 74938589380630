import React, { useRef, useEffect } from "react"
import { gsap } from "gsap/dist/gsap"

import s from "./IntroBlocks.module.scss"

import VidBar from "../../../images/vidbar.png"
import Susan from "../../../images/susan.png"
import Easy from "../../../images/easy.png"
import Never from "../../../images/never.png"

export const IntroBlocks = () => {
  const _speech1 = useRef()
  const _speech2 = useRef()

  const tl = new gsap.timeline({
    ease: `Power4.easeOut`,
    repeat: -1,
  })

  const tl2 = new gsap.timeline({
    ease: `Power4.easeOut`,
    repeat: -1,
  })

  useEffect(() => {
    if (!_speech1.current) return

    tl.to(_speech1.current, 0.6, {
      opacity: 0.6,
      delay: 2,
    })
      .to(_speech1.current, 0.3, {
        opacity: 1,
        scale: 1.2,
        rotate: -10,
      })
      .to(_speech1.current, 0.6, {
        opacity: 0.6,
        scale: 1,
        delay: 1,
        rotate: 0,
      })

    tl2
      .to(_speech2.current, 0.6, {
        opacity: 0.6,
        delay: 3,
      })
      .to(_speech2.current, 0.3, {
        opacity: 1,
        scale: 1.2,
        rotate: 15,
      })
      .to(_speech2.current, 0.6, {
        opacity: 0.6,
        scale: 1,
        delay: 1,
        rotate: 0,
      })
  }, [_speech1])

  return (
    <div className={s.Wrapper}>
      <div className={s.Inner}>
        <div className={s.Block1} style={{ backgroundImage: `url(${Susan})` }}>
          <div className={s.Name}>
            <span>Quizmaster Susan</span>
          </div>
        </div>
        <div className={s.Block2}>
          <img src={Easy} ref={_speech1} />
          <div className={s.Name}>
            <span>Elena</span>
          </div>
        </div>
        <div className={s.Block3}>
          <img src={Never} ref={_speech2} />
          <div className={s.Name}>
            <span>Yer Auld Da</span>
          </div>
        </div>
        <div
          className={s.Block4}
          style={{ backgroundImage: `url(${VidBar})` }}
        ></div>
      </div>
    </div>
  )
}
