import React, { useEffect, useRef, useState } from "react"
import Lottie from "react-lottie"
import { gsap } from "gsap/dist/gsap"

import s from "./Interstill.module.scss"

import * as animationData from "../../../lottis/intro.json"

export const Interstill = ({ close, children }) => {
  const _logo3 = useRef(null)
  const _wrapper = useRef()
  const [animationEnded, setAnimationEnded] = useState(false)

  const tl = new gsap.timeline({
    ease: `Power2.easeOut`,
  })

  useEffect(() => {
    if (!_logo3.current) return

    tl.to(
      _logo3.current,
      0.5,
      {
        opacity: 1,
        scale: 1,
      },
      "+=1"
    )
      .to(
        _logo3.current,
        0.5,
        {
          opacity: 0,
          scale: 0,
        },
        "+=1"
      )
      .to(
        _wrapper.current,
        0.2,
        {
          opacity: 0,
        },
        "+=0.5"
      )
  }, [_logo3])

  return (
    <div className={s.Wrapper} ref={_wrapper}>
      <div className={s.Inner}>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: animationData.default,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => {
                close()
              },
            },
          ]}
        />
        <div className={s.Back} />

        {/*<div ref={_rect0} className={`${s.Rect} ${s.Rect__rect0}`}>
          <Rect1 />
        </div>
        <div ref={_rect1} className={`${s.Rect} ${s.Rect__rect1}`}>
          <Rect1 />
        </div>
        <div ref={_rect2} className={`${s.Rect} ${s.Rect__rect2}`}>
          <Rect2 />
        </div>
        <div ref={_rect3} className={`${s.Rect} ${s.Rect__rect3}`}>
          <Rect3 />
        </div>
        <div ref={_rect4} className={`${s.Rect} ${s.Rect__rect4}`}>
          <Rect4 />
        </div>
 */}
        {/* <img src={Glass3} className={s.Logo} ref={_logo1} />
        <img src={Glass2} className={s.Logo} ref={_logo2} /> */}
        <div className={s.Child} ref={_logo3}>
          {children}
        </div>
      </div>
    </div>
  )
}
